import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import Footer from '../../Components/Home/Footer';
import DrawerComponent from './DrawerComponent';
import UserInfo from './UserInfo';

const Layout = ({ mobileOpen, handleDrawerToggle, user }) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <CssBaseline />

      {/* AppBar - Hidden on mobile */}
      <AppBar
        position="fixed"
        sx={{
          zIndex: theme.zIndex.drawer + 1, // Ensure AppBar is above the Drawer
          width: { sm: `calc(100% - 240px)` },
          ml: { sm: '240px' }, // Adjust for the drawer on larger screens
          display: { xs: 'none', sm: 'block' }, // Hide AppBar on mobile
        }}
      >
        <Toolbar>
          {/* Menu icon for mobile */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          {/* Site Name */}
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1 }}
          >
            Kahani Express
          </Typography>

          {/* User Info or Login Button */}
          {user ? (
            <UserInfo user={user} />
          ) : (
            <Button
              variant="contained"
              color="secondary"
              component={NavLink}
              to="/login"
            >
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>

      {/* Sidebar (DrawerComponent) */}
      <DrawerComponent
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: '#ffffff',
          p: 3,
          ml: { sm: '240px' }, // Adjust for the drawer on larger screens
          mt: { xs: 0, sm: '64px' }, // Remove AppBar height offset on mobile
        }}
      >
        {/* Rendered Pages */}
        <Outlet />

        {/* Footer */}
        <Footer />
      </Box>
    </Box>
  );
};

export default Layout;
