// src/features/messageSlice.js

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { config } from '../Common/Constants';
const URL = config.url;
// Fetch messages for a specific user
export const fetchMessages = createAsyncThunk('messages/fetchMessages', async (userId, { rejectWithValue }) => {
  try {
    const response = await fetch(`${URL}/inbox/${userId}`);
    const data = await response.json();
    if (!response.ok) {
      return rejectWithValue(data.message);
    }
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const messageSlice = createSlice({
  name: 'messages',
  initialState: {
    messages: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMessages.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.messages = action.payload;
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default messageSlice.reducer;
