import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { config } from '../Common/Constants';

const URL = config.url;

export const fetchStories = createAsyncThunk(
  'stories/fetchStories',
  async () => {
    console.log('fetchStories');
    const New_URL = `${URL}/kahanijson`;
    const response = await axios.get(New_URL);
    const parsedStories = response.data[0].stories.map((storyStr) =>
      JSON.parse(storyStr)
    );
    const sortedStories = parsedStories.sort(
      (a, b) => new Date(b.create_date) - new Date(a.create_date)
    );
    return sortedStories;
  }
);

const storiesSlice = createSlice({
  name: 'stories',
  initialState: {
    stories: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.stories = action.payload;
      })
      .addCase(fetchStories.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default storiesSlice.reducer;
