import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createStoryReducer from './features/createStorySlice'; // Import the story reducer
import messageReducer from './features/messageSlice';
import narrationReducer from './features/narrationSlice';
import settingsReducer from './features/settingsSlice';
import storiesReducer from './features/storiesSlice';
import storyModificationReducer from './features/storyModificationSlice';
import storyPageReducer from './features/storyPageSlice';
import storyReducer from './features/storySlice'; // For the individual story
import userReducer from './features/userSlice';
const persistConfig = {
  key: 'root',
  storage,
};

const persistedUserReducer = persistReducer(persistConfig, userReducer);

const store = configureStore({
  reducer: {
    user: persistedUserReducer,  // Persisted user reducer
    createStory: createStoryReducer,     // Story reducer
    messages: messageReducer,  // Message reducer 
    StoryModification: storyModificationReducer, // StoryModification reducer 
    stories: storiesReducer,  // Stories reducer   
    story: storyReducer, // Individual story reducer  
    settings: settingsReducer,// Settings reducer
    narration: narrationReducer, // Narration reducer
    storyPage: storyPageReducer, // Story page reducer
  },
});

export const persistor = persistStore(store);
export default store;
