// src/features/storySlice.js

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { config } from '../Common/Constants';

const URL = config.url;

// Async thunk for fetching a story by ID
export const fetchStoryById = createAsyncThunk(
  'story/fetchStoryById',
  async (storyId, thunkAPI) => {
    try {
      const New_URL = `${URL}/StoryJson/${storyId}`;
      const response = await axios.get(New_URL);

      if (response.data && response.data.storybook) {
        const storybook = JSON.parse(response.data.storybook);
        console.log('Fetched story:', storybook);
        return storybook;
      } else {
        return thunkAPI.rejectWithValue('Invalid story data structure');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data || 'Error fetching story'
      );
    }
  }
);

const storySlice = createSlice({
  name: 'story',
  initialState: {
    story: null,
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {
    // You can add reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStoryById.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchStoryById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.story = action.payload;
      })
      .addCase(fetchStoryById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      });
  },
});

export default storySlice.reducer;
