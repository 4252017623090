import React from 'react';

function Footer() {
  return (
    <footer style={{ textAlign: 'center', padding: '1rem', backgroundColor: '#f1f1f1', marginTop: 'auto' }}>
      <p>&copy; {new Date().getFullYear()} Kahani Express. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
