// src/features/storyPageSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentPage: 0,
};

const storyPageSlice = createSlice({
  name: 'storyPage',
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    nextPage: (state, action) => {
      const totalPages = action.payload;
      if (state.currentPage < totalPages - 1) {
        state.currentPage += 1;
      }
    },
    prevPage: (state) => {
      if (state.currentPage > 0) {
        state.currentPage -= 1;
      }
    },
    resetCurrentPage: (state) => {
      state.currentPage = 0;
    },
  },
});

export const {
  setCurrentPage,
  nextPage,
  prevPage,
  resetCurrentPage,
} = storyPageSlice.actions;
export default storyPageSlice.reducer;
