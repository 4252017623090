// UserInfo.js
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { signOut } from 'firebase/auth';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../../features/userSlice';
import { auth } from '../../firebase';
import './UserInfo.css';

const UserInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        dispatch(logoutUser());
        navigate('/login');
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };

  return (
    <Box className="user-info" sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="body1" sx={{ mr: 2 }}>
      Welcome, {user ? user.displayName || 'User' : 'User'}
      </Typography>
      <Button color="inherit" onClick={handleLogout}>
        Logout
      </Button>
    </Box>
  );
};

export default UserInfo;
