import { onAuthStateChanged } from 'firebase/auth';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ProtectedRoute from './Common/ProtectedRoute';
import Layout from './Components/Home/Layout';
import { auth } from './firebase';

// Lazy loading components
const Home = lazy(() => import('./Pages/Home/Home'));
const AboutUs = lazy(() => import('./Pages/Home/AboutUs'));
const StorySelector = lazy(() => import('./Pages/Story/StorySelector'));
const StoryViewer = lazy(() => import('./Pages/Story/StoryViewer'));
const StoryModification = lazy(() => import('./Pages/Modification/StoryModification'));
const Login = lazy(() => import('./Pages/Home/Login'));
const Signup = lazy(() => import('./Pages/Home/Signup'));
const CreateStory = lazy(() => import('./Pages/Modification/CreateStory'));
const MessageBoard = lazy(() => import('./Pages/Home/MessageBoard'));
const PrivacyPolicy = lazy(() => import('./Pages/Home/PrivacyPolicy'));
const Show = lazy(() => import('./Pages/Anime/Show'));
function App() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser ? currentUser : null);
    });
    return () => unsubscribe();
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {/* Layout Routes */}
          <Route
            path="/"
            element={
              <Layout
                mobileOpen={mobileOpen}
                handleDrawerToggle={handleDrawerToggle}
                user={user}
              />
            }
          >
            {/* Public Routes */}
            <Route index element={<StorySelector />} />
            <Route path="Home" element={<Home />} />
            <Route path="about" element={<AboutUs />} />
            <Route path="stories" element={<StorySelector />} />
            <Route path="story/:storyId" element={<StoryViewer />} />
            
            <Route path="storiesModification/:storyId" 
            element={
              <ProtectedRoute user={user}>
              <StoryModification />
              </ProtectedRoute>
              } />
            {/* Protected Routes */}
            <Route
              path="CreateStory"
              element={
                <ProtectedRoute user={user}>
                  <CreateStory />
                </ProtectedRoute>
              }
            />
            <Route
              path="MessageBoard"
              element={
                <ProtectedRoute user={user}>
                  <MessageBoard />
                </ProtectedRoute>
              }
            />
            <Route
              path="Show"
              element={
                <ProtectedRoute user={user}>
                  <Show />
                </ProtectedRoute>
              }
            />
            <Route path="privacy" element={<PrivacyPolicy />} />
            {/* Additional Routes */}
            <Route path="404" element={<div>Not Found</div>} />
            <Route path="500" element={<div>Server Error</div>} />
          </Route>

          {/* Authentication Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />

          {/* Catch-All Route */}
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
